<template>
  <div class="all">
    <!-- <router-link to="/user"><div class="goback"><</div></router-link> -->
    <van-nav-bar title="" left-arrow  @click-left="onClickLeft"></van-nav-bar>
    <img src="@/assets/img/info-image/12.jpg" class="first_Img" />
    <div class="video layout">
      <video width="320" height="300" controls="controls" ref="video">
        <source src="@/assets/video/a.mp4" type="video/mp4" />
      </video>
      <div class="video_img" @click="video()" ref="video_img" v-show="videoImg">
        <img src="@/assets/img/info-image/12_01.jpg" alt="" />
      </div>
    </div>
    <div class="layout">
      <div class="three layout">
        <img src="@/assets/img/info-image/11.png" class="three_Img" alt="" />
        <div class="three_text">şirket tanıtımı</div>
      </div>

      <div class="four">
        <img class="four_img" src="@/assets/img/info-image/12_02.jpg" alt="" />
        <div class="four_text">
          Happyjob, MCN kuruluşuna ait, ünlüler, ağ oluşturucular,Happyjob, MCN
          kuruluşuna ait, ünlüler, ağ oluşturucular,Happyjob, MCN kuruluşuna
          ait, ünlüler, ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler,
          ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler,
          ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ oluşturucular,
        </div>
      </div>
      <div class="three layout">
        <img src="@/assets/img/info-image/11.png" class="three_Img" alt="" />
        <div class="three_text">şirket tanıtımı</div>
      </div>
      <div class="four four2">
        <div class="four_text">
          Happyjob, MCN kuruluşuna ait, ünlüler, ağ oluşturucular,Happyjob, MCN
          kuruluşuna ait, ünlüler, ağ oluşturucular,Happyjob, MCN kuruluşuna
          ait, ünlüler, ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler,
          ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler,
          ağ oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ
          oluşturucular,Happyjob, MCN kuruluşuna ait, ünlüler, ağ oluşturucular,
        </div>
      </div>
    </div>
    <div class="video layout">
      <!-- <video width="320" height="300" controls="controls" ref="video">
        <source src="@/assets/video/course.mp4" type="video/mp4" />
      </video> -->
      <div class="five layout_across">
        <div>
          <img src="@/assets/img/info-image/1_13.jpg" width="94%" alt="" />
        </div>
        <div class="layout five_img1">
          <img src="@/assets/img/info-image/2_13.jpg" width="94%" alt="" />
          <img src="@/assets/img/info-image/3_13.jpg" width="94%" alt="" />
        </div>
      </div>
    </div>
    <div class="video layout">
      <video width="320" height="300" controls="controls" ref="video2">
        <source src="@/assets/video/a.mp4" type="video/mp4" id="ve" />
      </video>
      <div
        class="video_img"
        @click="video2()"
        ref="video_img"
        v-show="videoImg2"
      >
        <img src="@/assets/img/info-image/12_01.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoImg: true,
      videoImg2: true,
    };
  },
  methods: {
    video() {
      this.videoImg = false;
      this.$refs.video.play();
    },
    video2() {
      this.videoImg2 = false;
      this.$refs.video2.play();
    },
    onClickLeft() {
      this.$router.push("/user");
    },
  },
};
</script>

<style  scoped>
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout_across {
  display: flex;
}
.first_Img {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.video {
  position: relative;
  /* margin-top: 20px; */
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
}
.video_img {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.video_img img {
  width: 98%;
  margin-left: 4px;
  /* margin-right: 10px; */
  /* height: 200px; */
}
.three {
  box-sizing: border-box;
  margin-top: 35px;
  margin-bottom: 40px;
}
.three_Img {
  width: 86%;
}
.three_text {
  color: #0b7be4;
  font-size: 19px;
  margin-top: -55px;
}
.four {
  width: 98%;
  /* height: 300px; */
  border: 1px solid #f8b660;
  border-radius: 5px;
  padding: 10px 0 10px 5px;
  word-break: break-all;
  overflow: hidden;
  /* overflow-y: scroll; */
  margin-bottom: 10px;
  text-align: left;
}
.four2 {
  padding-left: 20px;
  padding-right: 30px;
}
.four_img {
  width: 45%;
  border: 1px solid #ac6a00;
  border-radius: 5px;
  float: right;
}
.four_text {
  font-size: 6px;
  line-height: 20px;
  letter-spacing: 0.1ex;
  word-wrap: break-word;
  word-break: keep-all;
}
.five {
  width: 98%;
  height: 205px;
  padding: 10px;
  border: 1px solid #f8b660;
  border-radius: 5px;
  /* margin-bottom: 60px; */
}
.five_img1 {
  justify-content: space-between;
}
</style>